import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Video, VideoChat, Heartbeater } from "Components";
import { BodySection, Layout } from "../Layout";
import { AuthAccess } from "services";

export const Show = ({ event = {}, token }) => {
  const { heading, subHeading, body, videoId, chatId, chatActive } = event;
  return (
    <>
      <Layout
        siteMaxWidth={"1400px"}
        heroContent={
          chatActive ? (
            <VideoChat videoId={videoId} chatId={chatId} />
          ) : (
            <Video id={videoId} />
          )
        }
        heading={heading}
        subHeading={subHeading}
        heroPadding={chatActive ? 0 : "56.24%"}
        videoStyle={
          chatActive ? { position: "relative", width: "100%" } : undefined
        }
        showFooterLogo
      >
        {body ? <BodySection>{ReactHtmlParser(body)}</BodySection> : undefined}
      </Layout>
      <AuthAccess token={token} />
      <Heartbeater />
    </>
  );
};
