import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { FieldMessage, Checkbox } from "Components";

export const OptIn = ({
  onChange,
  value,
  field,
  error = {},
  fieldCheck,
  ...props
}) => {
  const handleChange = (value) => {
    onChange({ [field.name]: value });
  };
  if (field.checked && value !== false) {
    onChange({ [field.name]: field.checked });
  }
  return (
    <>
      <Checkbox
        title={<Formatting>{ReactHtmlParser(field.label)}</Formatting>}
        checked={value}
        error={!!error.status}
        onChange={handleChange}
        disabled={!fieldCheck}
        {...props}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};

const Formatting = styled.div`
  color: ${(props) => props.theme.register.checkboxLabel.color};
  font-family: ${(props) => props.theme.register.checkboxLabel.font};
  font-size: ${(props) => props.theme.register.checkboxLabel.fontSize[0]};
  font-weight: ${(props) => props.theme.register.checkboxLabel.fontWeight};
  line-height: ${(props) => props.theme.register.checkboxLabel.lineHeight[0]};
  text-transform: ${(props) =>
    props.theme.register.checkboxLabel.textTransform};
  a,
  a:hover {
    color: ${(props) => props.theme.register.checkboxLabel.color};
    text-decoration: underline;
  }
  small {
    font-size: 80%;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: ${(props) => props.theme.register.checkboxLabel.fontSize[1]};
    line-height: ${(props) => props.theme.register.checkboxLabel.lineHeight[1]};
  }
`;
