export const global = {
  // Top level site container
  siteContainer: {
    maxWidth: "1400px",
    background: "#ffffff",
  },
  header: {
    background: "#000000",
    alignContent: ["center", "flex-start"],
    padding: ["20px 24px", "20px 40px"],
    logo: {
      width: ["180px", "221px"],
    },
  },
  video: {
    background: "#1e1e1e",
  },
  // Text banner below video
  heading: {
    background: "#191C22",
    padding: ["20px 24px", "20px 24px"],
    // Text styling
    color: "#ffffff",
    fontSize: ["32px", "75px"],
    lineHeight: ["32px", "55px"],
    fontWeight: 700,
    textAlign: ["center", "center"],
    textTransform: "uppercase",
  },
  subHeading: {
    margin: ["0 0 24px 0", "0 0 32px 0"],
    // Text styling
    color: "#0065C3",
    fontSize: ["18px", "32px"],
    fontWeight: 700,
    textTransform: "uppercase",
    textAlign: ["center", "center"],
  },
  body: {
    background: "#ffffff",
    padding: ["24px", "45px 40px 55px 40px"],
    alignContent: ["center", "center"],
    // Text styling
    color: "#505050",
    fontSize: ["16px", "18px"],
    fontWeight: 300,
    textTransform: "none",
  },
  addons: {
    padding: ["50px 0", "100px 0"],
    background:
      "linear-gradient(to top, rgb(5, 66, 131) 0%, rgb(1, 95, 184) 40%, rgb(0, 101, 195) 100%)",
  },
  footer: {
    background: "#000000",
    alignContent: ["left", "left"],
    padding: ["30px 32px", "30px 32px"],
    logo: {
      width: ["180px", "221px"],
    },
  },
};
