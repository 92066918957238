import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { GridColumn, GridRow } from "notes";
import { Video } from "Components";

export const VideoChat = ({ videoId, chatId, ...props }) => {
  const [height, setHeight] = useState(0);

  const ref = useRef(null);

  useEffect(() => {
    if (ref) {
      setHeight(window.innerHeight - (ref.current.clientHeight + 79));
    }
  }, [ref]);

  return (
    <Container {...props}>
      <VideoContainer ref={ref}>
        <Video id={videoId} />
      </VideoContainer>
      <ChatWindow
        src={`https://vimeo.com/event/${chatId}/chat/`}
        height="100%"
        frameborder="0"
        calcHeight={height}
      />
    </Container>
  );
};

const Container = styled(GridColumn)`
  position: relative;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    flex-direction: row;
  }
`;

const VideoContainer = styled(GridRow).attrs({ as: "div" })`
  display: flex;
  width: 100%;
`;

const ChatWindow = styled.iframe`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: ${(props) => props.calcHeight}px;
  @media only screen and ${(props) => props.theme.media.medium} {
    flex-direction: row;
    width: 392px;
    height: auto;
  }
`;
