import React, { useContext, useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { EventContext, TimeContext, firebase } from "Components";
import {
  PreShow,
  Show,
  PostShow,
  Register,
  AdminControls,
  Preview,
} from "./Components";

const adminViews = ["register", "preview", "preshow", "show", "postshow"];
export const Root = () => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const params = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(true);
  const [registered, setRegistered] = useState(false);
  const [cookies, setCookie] = useCookies([process.env.REACT_APP_EVENT_ID]);
  const [adminView, setAdminView] = useState(null);

  const handleRegister = useCallback(
    (isAdmin, token) => {
      setCookie("registered", true, { maxAge: 31622400 });
      setCookie("token", token, { maxAge: 31622400 });
      setRegistered(true);
      if (isAdmin) {
        setCookie("admin", true, { maxAge: 31622400 });
        window.location.reload();
      }
    },
    [setCookie]
  );

  useEffect(() => {
    (async () => {
      if (params.get("token") && !cookies.token) {
        const verify = firebase.functions().httpsCallable("auth-verify");
        try {
          const verified = await verify({
            eventId: process.env.REACT_APP_EVENT_ID,
            token: params.get("token"),
          });
          if (verified?.data?.success) {
            handleRegister(verified?.data?.admin, params.get("token"));
          }
        } catch (error) {
          console.error("token validation failed");
        }
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return null;
  }

  if (!event.preview) {
    delete adminViews[1];
  }

  const register = {
    ...event.register,
    start: event.start,
    artist: event.artist,
    title: event.title,
    mastercampaignId: event.mastercampaignId,
    altLogo: event.logos?.header,
    headerLogo: event.logos?.header,
    footerLogo: event.logos?.footer,
    overlayImage: event.logos?.footer,
  };

  const getAdminView = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const viewHistory = urlParams.get("view");

    switch (viewHistory || adminView) {
      case "register":
        return <Register onSuccess={handleRegister} event={register} />;
      case "preshow":
        return <PreShow event={{ ...event.preShow, start: event.start }} />;
      case "show":
        return <Show event={event.show} token={cookies.token} />;
      case "postshow":
        return <PostShow event={event.postShow} />;
      case "preview":
        return <Preview event={event.preview} />;
      default:
        return null;
    }
  };

  if (cookies?.admin) {
    return (
      <>
        <AdminControls
          views={adminViews}
          selectedView={adminView}
          setAdminView={setAdminView}
        />
        {getAdminView()}
      </>
    );
  }

  if (!cookies?.registered && !registered) {
    // show registration page to all non-registered viewers
    return (
      <Register
        onSuccess={handleRegister}
        event={register}
        registered={false}
      />
    );
  } else if (time < event.endSweeps) {
    // show registration page (with confirmation) until sweepstakes ends
    return (
      <Register onSuccess={handleRegister} event={register} registered={true} />
    );
  }

  if (event.countdown > time && !!event.preview) {
    return <Preview event={event.preview} />;
  }

  if (
    event.start > time.plus({ minutes: event.preshowTransitionMinutes || 30 })
  ) {
    return <PreShow event={{ ...event.preShow, start: event.start }} />;
  }

  if (event.end < time) {
    return <PostShow event={event.postShow} />;
  }

  return <Show event={event.show} token={cookies.token} />;
};
