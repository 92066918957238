import React from "react";
import { Select as Select2 } from "notes";
import { FieldMessage } from "Components";

export const Select = ({ onChange, value, field, error = {} }) => {
  let selected =
    value && field.options.find(({ text }) => text.includes(value));
  const handleChange = (value) => {
    onChange({ [field.name]: value });
  };
  return (
    <>
      <Select2
        placeholder={field.placeholder ?? "Select an option..."}
        selected={selected}
        options={field.options}
        onChange={(val) => handleChange(val.text)}
        style={{ cursor: "default" }}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};
