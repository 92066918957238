import React from "react";
import styled from "styled-components";
import { GridRow, GridColumn, P } from "notes";

export const Countdown = ({ label, days, hours, minutes, seconds }) => {
  return (
    <CountdownContainer xCenter>
      <CountdownTitle>{label}</CountdownTitle>
      <GridRow>
        {days >= 1 && (
          <Outline xCenter>
            <Increment>{days}</Increment>
            <Label>Day{days !== 1 ? "s" : ""}</Label>
          </Outline>
        )}
        <Outline xCenter>
          <Increment>{hours}</Increment>
          <Label>Hour{hours !== 1 ? "s" : ""}</Label>
        </Outline>
        <Outline xCenter>
          <Increment>{minutes}</Increment>
          <Label>Minute{minutes !== 1 ? "s" : ""}</Label>
        </Outline>
        {days < 1 && (
          <Outline xCenter>
            <Increment>{seconds}</Increment>
            <Label>Second{seconds !== 1 ? "s" : ""}</Label>
          </Outline>
        )}
      </GridRow>
    </CountdownContainer>
  );
};

const CountdownTitle = styled.h3`
  color: ${(props) => props.theme.preShow.countdownLabel.color};
  font-family: ${(props) => props.theme.fontStacks.header};
  font-size: ${(props) => props.theme.preShow.countdownLabel.fontSize[0]};
  font-weight: ${(props) => props.theme.preShow.countdownLabel.fontWeight};
  line-height: ${(props) => props.theme.preShow.countdownLabel.lineHeight[0]};
  margin-bottom: ${(props) =>
    props.theme.preShow.countdownLabel.marginBottom[0]};
  margin-top: -8px;
  text-transform: ${(props) =>
    props.theme.preShow.countdownLabel.textTransform};

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: ${(props) => props.theme.preShow.countdownLabel.fontSize[1]};
    line-height: ${(props) => props.theme.preShow.countdownLabel.lineHeight[1]};
    margin-bottom: ${(props) =>
      props.theme.preShow.countdownLabel.marginBottom[1]};
    margin-top: -25px;
  }
`;

const Increment = styled.p`
  color: ${(props) => props.theme.preShow.increment.color};
  font-family: ${(props) => props.theme.fontStacks.header};
  font-weight: ${(props) => props.theme.preShow.increment.fontWeight};
  font-size: ${(props) => props.theme.preShow.increment.fontSize[0]};
  line-height: ${(props) => props.theme.preShow.increment.lineHeight[0]};

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: ${(props) => props.theme.preShow.increment.fontSize[1]};
    line-height: ${(props) => props.theme.preShow.increment.lineHeight[1]};
  }
`;

const Label = styled.div`
  color: ${(props) => props.theme.preShow.incrementLabel.color};
  font-family: ${(props) => props.theme.fontStacks.header};
  font-weight: ${(props) => props.theme.preShow.incrementLabel.fontWeight};
  font-size: ${(props) => props.theme.preShow.incrementLabel.fontSize[0]};
  line-height: ${(props) => props.theme.preShow.incrementLabel.lineHeight[0]};
  text-transform: uppercase;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: ${(props) => props.theme.preShow.incrementLabel.fontSize[1]};
    line-height: ${(props) => props.theme.preShow.incrementLabel.lineHeight[1]};
  }
`;

const CountdownContainer = styled(GridColumn)`
  background-color: #000013;
  justify-content: center;
  height: 100%;
  width: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    justify-content: center;
  }
`;

const Outline = styled(GridColumn)`
  border: ${(props) => props.theme.preShow.incrementBorder};
  justify-content: space-between;
  padding: 14px 0;
  width: 89px;
  & + & {
    margin-left: 9px;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 38px 0;
    width: 236px;
    & + & {
      margin-left: 24px;
    }
  }
`;
