import React, { forwardRef } from "react";
import styled from "styled-components";

export const Input = forwardRef((props, ref) =>
  (({
    onChange,
    value,
    placeholder,
    error,
    disabled,
    InternalField = TextField,
    placeholderStyle,
    ...props
  }) => {
    const handleChange = (e) => {
      const {
        target: { value },
      } = e;
      return onChange(value, e);
    };
    return (
      <TextContainer error={error} disabled={disabled}>
        <Placeholder visible={value}>{placeholder}</Placeholder>
        <InternalField
          onChange={handleChange}
          value={value}
          error={error}
          disabled={disabled}
          {...props}
        />
      </TextContainer>
    );
  })({ ...props, ref })
);

const TextContainer = styled.div`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.register.input.backgroundDisabled
      : props.theme.register.input.background};
  border-radius: ${(props) => props.theme.register.input.borderRadius};
  display: block;
  position: relative;
  flex-grow: 1;
  ${(props) =>
    props.error &&
    `background-color: ${props.theme.register.input.backgroundError};`}
`;

const Placeholder = styled.div`
  align-items: center;
  box-sizing: border-box;
  color: ${(props) => props.theme.register.input.placeholder.color};
  display: ${(props) => (props.visible ? "none" : "flex")};
  font-family: ${(props) => props.theme.register.input.placeholder.font};
  font-size: ${(props) => props.theme.register.input.placeholder.fontSize[0]};
  font-weight: ${(props) => props.theme.register.input.placeholder.fontWeight};
  line-height: ${(props) =>
    props.theme.register.input.placeholder.lineHeight[0]};
  justify-content: ${(props) =>
    props.theme.register.input.placeholder.textAlign[0]};
  text-transform: ${(props) =>
    props.theme.register.input.placeholder.textTransform};
  height: ${(props) => props.theme.register.input.height[0]};
  padding: ${(props) => props.theme.register.input.placeholder.padding};
  position: absolute;
  width: 100%;
  z-index: 1;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: ${(props) => props.theme.register.input.placeholder.fontSize[1]};
    line-height: ${(props) =>
      props.theme.register.input.placeholder.lineHeight[1]};
    justify-content: ${(props) =>
      props.theme.register.input.placeholder.textAlign[1]};
  }
`;

const TextField = styled.input`
  background-color: transparent;
  border: ${(props) => props.theme.register.input.border};
  border-radius: ${(props) => props.theme.register.input.borderRadius};
  box-sizing: border-box;
  color: ${(props) => props.theme.register.input.color};
  display: block;
  font-family: ${(props) => props.theme.register.input.font};
  font-size: ${(props) => props.theme.register.input.fontSize[0]};
  height: ${(props) => props.theme.register.input.height[0]};
  line-height: ${(props) => props.theme.register.input.lineHeight[0]};
  outline: 0;
  padding: ${(props) => props.theme.register.input.padding};
  position: relative;
  text-align: ${(props) => props.theme.register.input.textAlign[0]};
  text-transform: ${(props) => props.theme.register.input.textTransform};
  width: 100%;
  z-index: 2;
  -webkit-appearance: none;
  &:focus {
    border: ${(props) => props.theme.register.input.borderFocus};
    box-shadow: 0 0 4px 0
      ${(props) => props.theme.register.input.borderFocusShadow};
  }
  &:disabled {
    border: ${(props) => props.theme.register.input.borderDisabled};
  }

  ${(props) =>
    props.error &&
    `border: ${props.theme.register.input.borderError};
    border-width: 2px;
    padding-top: 6px;
    padding-bottom: 6px;
    &:focus {
      border: ${props.theme.register.input.border}; }`};

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: ${(props) => props.theme.register.input.fontSize[1]};
    height: ${(props) => props.theme.register.input.height[1]};
    line-height: ${(props) => props.theme.register.input.lineHeight[1]};
    text-align: ${(props) => props.theme.register.input.textAlign[1]};
  }
`;
