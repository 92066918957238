import React from "react";
import { ThemeProvider } from "styled-components";
import { MAXTheme } from "notes";
import { theme } from "theme";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { MessageProvider } from "Components";
import { Root } from "./Root";
import { Reminder } from "./Reminder";

const mergedTheme = {
  ...MAXTheme,
  ...theme,
};

export const Routes = () => {
  return (
    <ThemeProvider theme={mergedTheme}>
      <MessageProvider>
        <Router>
          <Switch>
            <Route exact path="/reminder" component={Reminder} />
            <Route path="/" component={Root} />
            <Redirect to="/" />
          </Switch>
        </Router>
      </MessageProvider>
    </ThemeProvider>
  );
};
