import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import ReactHtmlParser from "react-html-parser";
// import { Grid, GridColumn, GridRow, H3 as h3 } from "notes";
import { Carousel, ProductAd, EventContext } from "Components";
// import { responsiveCalc } from "Modules";
// import logo from "../../../Images/us-marines-logo.png";
import analytics from "services/analytics";

export const Layout = ({
  siteMaxWidth = "2000px",
  children,
  heading,
  subHeading,
  heroContent,
  heroPadding = "56.25%",
  noFooter,
  noHeader,
  noPadding,
  noAddons,
  videoStyle,
  showFooterLogo = false,
  showFooterText = false,
  altLogo,
  layoutRegistration = false,
  ...props
}) => {
  const themeContext = useContext(ThemeContext);
  const { event } = useContext(EventContext);

  let api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  let headerLogo = `${api}/o/${event.logos.header?.path}?alt=media`;
  let footerLogo = `${api}/o/${event.logos.footer?.path}?alt=media`;

  const onClickHandler = () => {
    analytics.fireEvent({
      category: "External Link",
      action: "Privacy Policy",
      label: window.location.host,
    });
  };

  return (
    <SiteContainer siteMaxWidth={siteMaxWidth}>
      {!noHeader && (
        <Header as="header">
          <HeaderContainer>
            <HeaderLogo src={headerLogo} />
          </HeaderContainer>
        </Header>
      )}

      {heroContent && (
        <Hero id="hero">
          <HeroContainer>
            <HeroContent style={videoStyle}>{heroContent}</HeroContent>
          </HeroContainer>
        </Hero>
      )}

      {heading && (
        <Heading id="heading">
          <HeadingContainer>
            <HeadingContent>{heading}</HeadingContent>
          </HeadingContainer>
        </Heading>
      )}

      {!!children && (
        <Main as="main" {...props}>
          <MainContainer noPadding={noPadding}>
            {subHeading && (
              <Subheading>{ReactHtmlParser(subHeading)}</Subheading>
            )}
            {children}
          </MainContainer>
        </Main>
      )}

      {!noAddons &&
        (themeContext?.productAd?.active || themeContext?.carousel?.active) && (
          <AddOns as="section">
            {themeContext?.productAd?.active && <ProductAd />}
            {themeContext?.carousel?.active && <Carousel />}
          </AddOns>
        )}

      {!noFooter && (
        <Footer as="footer">
          <FooterContainer layoutRegistration={layoutRegistration}>
            {showFooterText && (
              <p>
                <a
                  href="https://musicaudienceexchange.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={onClickHandler}
                >
                  Privacy Policy
                </a>
                , powered by Music Audience Exchange
              </p>
            )}
            {showFooterLogo && (
              <FooterLogo
                src={footerLogo}
                layoutRegistration={layoutRegistration}
              />
            )}
          </FooterContainer>
        </Footer>
      )}
    </SiteContainer>
  );
};

// ======== SITE CONTAINER ======== //

const SiteContainer = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.fontStacks.default};
  line-height: 1.25;
  margin: 0 auto;
  max-width: ${(props) => props.siteMaxWidth};
  min-height: 100vh;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.maxMedia.large} {
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.65);
  }
`;

// ======== HEADER ======== //

const Header = styled.header`
  background: #000013;
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px 24px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    justify-content: flex-start;
    padding: 20px 30px;
  }
`;

const HeaderLogo = styled.img`
  max-width: 290px;
  width: 100%;
`;

// ======== HERO ======== //

const Hero = styled.div`
  background: #1e1e1e;
`;

const HeroContainer = styled.div`
  padding-top: 56.25%;
  position: relative;
  width: 100%;
`;

const HeroContent = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .vimeo {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

// ======== HEADING ======== //

const Heading = styled.div`
  background: #191c22;
`;

const HeadingContainer = styled.div`
  padding: 20px 24px;
`;

const HeadingContent = styled.h3`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontStacks.header};
  font-size: 32px;
  font-weight: 700;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 75px;
  }
`;

// ======== MAIN / BODY ======== //

const Main = styled.div`
  background: #ffffff;
  color: #000013;
  display: flex;
  flex: 1;
`;

const MainContainer = styled.div`
  display: flex;
  padding: ${(props) => (props.noPadding ? 0 : "25px")};
  width: 100%;
  background: #000013;
  color: white;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: ${(props) => (props.noPadding ? 0 : "45px 40px 55px 40px")};
  }
`;

const Subheading = styled.div`
  color: #0065c3;
  font-family: ${(props) => props.theme.fontStacks.header};
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 24px 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 32px;
    margin: 0 0 32px 0;
  }
`;

// ======== ADD-ONS ======== //

const AddOns = styled.div`
  background: #ffffff;
  padding: 50px 0;
  width: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 100px 0;
  }
`;

// ======== FOOTER ======== //

const Footer = styled.footer`
  background: #000013;
  color: #ffffff;
  font-size: 13px;

  a {
    color: #ffffff;
  }

  a:visited {
    color: #ffffff;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  ${(props) => props.layoutRegistration && "flex-direction: column-reverse;"};
  padding: ${(props) => (props.layoutRegistration ? "0" : "30px 32px")};
  width: 100%;
  align-items: center;

  p {
    padding: 30px 16px;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    ${(props) =>
      props.layoutRegistration &&
      `flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      
      p {
        padding: 0 16px;
      }`};
  }
`;

const FooterLogo = styled.img`
  width: 100%;
  ${(props) =>
    !props.layoutRegistration &&
    `margin: 0 auto;
    max-width: 420px;`};

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: ${(props) => (props.layoutRegistration ? "39%" : "100%")};
    max-width: ${(props) => props.layoutRegistration && "630px"};
  }
`;

export const BodySection = styled.div`
  font-family: ${(props) => props.theme.fontStacks.default};
  font-size: 18px;
  max-width: 900px;
  margin: 0 auto;

  p {
    border-left: 5px solid #b00d25;
    padding-left: 15px;
  }

  small {
    font-size: 50%;
  }
`;
