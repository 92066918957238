import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  GridColumn,
  H3,
  P,
  ButtonPrimary,
  Footnote,
  Label,
  LoaderInline,
  H2,
} from "notes";
import {
  PhoneMask,
  firebase,
  MessageContext,
  FieldMessage,
  EventContext,
} from "Components";
import { Layout } from "../Root/Components/Layout";

export const Reminder = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const defaultNumber = urlParams.get("phone");
  const userToken = urlParams.get("token");

  const [phoneValue, setPhoneValue] = useState(defaultNumber);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { displayMessage } = useContext(MessageContext);
  const { event } = useContext(EventContext);

  const history = useHistory();

  const handleSubmit = async () => {
    if (!!phoneValue && phoneValue.length >= 10) {
      setLoading(true);
      try {
        const authRemind = firebase.functions().httpsCallable("auth-remind");
        authRemind({
          token: userToken,
          phone: phoneValue,
          eventId: process.env.REACT_APP_EVENT_ID,
        }).then(({ data }) => {
          if (data.success) {
            history.push(
              `/?token=${encodeURIComponent(decodeURIComponent(userToken))}`
            );
            displayMessage({
              color: "green",
              children:
                "Your reminder and link will be sent on the day of the show!",
              timeout: 20000,
            });
          } else {
            setLoading(false);
            setError(
              "There was an issue with your request. Please try clicking the link from your email again."
            );
          }
        });
      } catch (error) {
        setLoading(false);
        setError(error.message);
      }
    } else {
      setError("You must enter a valid phone number to receive a reminder.");
    }
  };

  return (
    <LayoutStyle noPadding noAddons>
      <Container yCenter>
        {!!event?.reminder && <Heading>{event?.reminder?.heading}</Heading>}
        <Card>
          <SubHeading>Text Message Reminder</SubHeading>
          <Description>
            Text me a reminder 30 minutes before the show with my ticket link
          </Description>
          <StyledLabel>Mobile Phone Number</StyledLabel>
          <StyledInput
            phoneNumber={phoneValue}
            onChange={(value) => {
              setPhoneValue(value);
              setError("");
            }}
            error={!!error}
            placeholder="Phone Number"
          />
          <FieldMessage active={!!error} content={error} />
          <StyledFootnote>
            Message and data rates may apply. Consent is not a condition of
            purchase. Text back STOP to cancel
          </StyledFootnote>
          <Button
            disabled={loading}
            onClick={handleSubmit}
            style={{ marginTop: "40px" }}
          >
            {loading ? <LoaderInline /> : "Text me a reminder!"}
          </Button>
        </Card>
      </Container>
    </LayoutStyle>
  );
};

const LayoutStyle = styled(Layout)`
  background-color: ${(props) => props.theme.reminder.background[0]};
  flex-grow: 1;
  text-align: left;
  @media only screen and ${(props) => props.theme.media.medium} {
    background-color: ${(props) => props.theme.reminder.background[1]};
  }
`;

const Container = styled(GridColumn)`
  height: 100%;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  max-width: 472px;
  padding-top: 40px;
`;

const Heading = styled(H2)`
  color: ${(props) => props.theme.reminder.heading.color};
  font-family: ${(props) => props.theme.reminder.heading.font};
  font-size: ${(props) => props.theme.reminder.heading.fontSize[1]};
  line-height: ${(props) => props.theme.reminder.heading.lineHeight[1]};
  max-width: ${(props) => props.theme.reminder.heading.maxWidth[1]};
  padding: 0 40px;
  text-align: ${(props) => props.theme.reminder.heading.textAlign[1]};
  text-transform: ${(props) => props.theme.reminder.heading.textTransform};
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: ${(props) => props.theme.reminder.heading.fontSize[0]};
    line-height: ${(props) => props.theme.reminder.heading.lineHeight[0]};
    max-width: ${(props) => props.theme.reminder.heading.maxWidth[0]};
    padding: 0 24px;
    text-align: ${(props) => props.theme.reminder.heading.textAlign[0]};
  }
`;

const Card = styled(GridColumn)`
  background-color: #ffffff;
  border-radius: 8px;
  color: #222222;
  flex-grow: 1;
  padding: ${(props) => props.theme.reminder.card.padding[0]};
  position: relative;
  width: 100%;
  max-width: 472px;

  @media only screen and ${(props) => props.theme.media.medium} {
    border: ${(props) => props.theme.reminder.card.border};
    flex-grow: 0;
    margin: 24px auto 72px auto;
    padding: ${(props) => props.theme.reminder.card.padding[1]};
  }
`;

const SubHeading = styled(H3)`
  color: ${(props) => props.theme.reminder.subHeading.color};
  font-family: ${(props) => props.theme.reminder.subHeading.font};
  font-size: ${(props) => props.theme.reminder.subHeading.fontSize[0]};
  font-weight: ${(props) => props.theme.reminder.subHeading.fontWeight};
  line-height: ${(props) => props.theme.reminder.subHeading.lineHeight[0]};
  margin-bottom: 4px;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) => props.theme.reminder.subHeading.fontSize[1]};
    line-height: ${(props) => props.theme.reminder.subHeading.lineHeight[1]};
  }
`;

const Description = styled(P)`
  color: ${(props) => props.theme.reminder.description.color};
  font-family: ${(props) => props.theme.reminder.description.font};
  font-size: ${(props) => props.theme.reminder.description.fontSize[0]};
  font-weight: ${(props) => props.theme.reminder.description.fontWeight};
  line-height: ${(props) => props.theme.reminder.description.lineHeight[0]};
  margin: ${(props) => props.theme.reminder.description.margin[0]};
  text-align: ${(props) => props.theme.reminder.description.textAlign[0]};
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) => props.theme.reminder.description.fontSize[1]};
    line-height: ${(props) => props.theme.reminder.description.lineHeight[1]};
    margin: ${(props) => props.theme.reminder.description.margin[1]};
    text-align: ${(props) => props.theme.reminder.description.textAlign[1]};
  }
`;

const StyledLabel = styled(Label)`
  color: ${(props) => props.theme.reminder.label.color};
  font-family: ${(props) => props.theme.reminder.label.font};
  font-size: ${(props) => props.theme.reminder.label.fontSize[0]};
  font-weight: ${(props) => props.theme.reminder.label.fontWeight};
  line-height: ${(props) => props.theme.reminder.label.lineHeight[0]};
  margin-bottom: 4px;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) => props.theme.reminder.label.fontSize[1]};
    line-height: ${(props) => props.theme.reminder.label.lineHeight[1]};
  }
`;

const StyledInput = styled(PhoneMask)`
  border: ${(props) => props.theme.reminder.input.border};
  border-radius: ${(props) => props.theme.reminder.input.borderRadius};
  color: ${(props) => props.theme.reminder.input.color};
`;

const StyledFootnote = styled(Footnote)`
  margin-top: 8px;
  color: ${(props) => props.theme.reminder.footnote.color};
  font-family: ${(props) => props.theme.reminder.footnote.font};
  font-size: ${(props) => props.theme.reminder.footnote.fontSize[0]};
  font-weight: ${(props) => props.theme.reminder.footnote.fontWeight};
  line-height: ${(props) => props.theme.reminder.footnote.lineHeight[0]};
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) => props.theme.reminder.footnote.fontSize[1]};
    line-height: ${(props) => props.theme.reminder.footnote.lineHeight[1]};
  }
`;

const Button = styled(ButtonPrimary)`
  align-items: center;
  background-color: ${(props) => props.theme.reminder.button.fill};
  border: ${(props) => props.theme.reminder.button.border};
  border-radius: ${(props) => props.theme.reminder.button.borderRadius};
  box-shadow: none;
  color: ${(props) => props.theme.reminder.button.color};
  font-family: ${(props) => props.theme.reminder.button.font};
  font-size: ${(props) => props.theme.reminder.button.fontSize[0]};
  font-weight: ${(props) => props.theme.reminder.button.fontWeight};
  line-height: ${(props) => props.theme.reminder.button.lineHeight[0]};
  height: ${(props) => props.theme.reminder.button.height[0]};
  transition: all ease 0.3s;
  text-transform: ${(props) => props.theme.reminder.button.textTransform};
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) => props.theme.reminder.button.fontSize[1]};
    line-height: ${(props) => props.theme.reminder.button.lineHeight[1]};
    height: ${(props) => props.theme.reminder.button.height[1]};
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.reminder.button.fillHover};
    box-shadow: none;
    color: ${(props) => props.theme.reminder.button.colorHover};
    border: ${(props) => props.theme.reminder.button.borderHover};
  }
`;
