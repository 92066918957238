import {
  global,
  register,
  preview,
  preShow,
  show,
  postShow,
  reminder,
} from "./views";
import { carousel, productAd } from "./components";

const fonts = {
  header: "'BrandBold', 'Montserrat', sans-serif",
  default: "'BrandRegular', 'Arial', 'Roboto', 'Helvetica', sans-serif",
};

export const theme = {
  colors: {
    inputBg: "#ffffff",
    inputText: "#676767",
    inputPlaceholderText: "#676767",
  },
  // fonts to use in theme
  fontStacks: {
    header: fonts.header,
    default: fonts.default,
  },
  // overrides styled-component fonts
  fonts: {
    header: `font-family: ${fonts.header};`,
    default: `font-family: ${fonts.default};`,
  },
  // custom media queries
  maxMedia: {
    small: "(min-width: 768px)",
    medium: "(min-width: 950px)",
    large: "(min-width: 1240px)",
  },
  // Views
  ...global,
  register,
  preview,
  preShow,
  show,
  postShow,
  reminder,
  // Components
  carousel,
  productAd,
};
