import React, { useContext, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import styled, { ThemeContext } from "styled-components";
import { GridColumn, H2, H3, P, Modal, Link } from "notes";
import { RegistrationForm } from "./Components";
import { Layout } from "../Layout";
// import { responsiveCalc } from "Modules";
import playIcon from "../../../../Images/play-button.png";
import contentAd from "../../../../Images/content-ad.jpg";
import analytics from "services/analytics";

export const Register = React.memo(({ event = {}, onSuccess, ...props }) => {
  const {
    body,
    heading,
    subHeading,
    artistImageDesktop,
    artistImageMobile,
    altLogo,
    overlayImage,
  } = event;
  const themeContext = useContext(ThemeContext);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [open, setOpen] = useState(false);

  let api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  let desktopImage = `${api}/o/${artistImageDesktop?.path}?alt=media`;
  let mobileImage = `${api}/o/${artistImageMobile?.path}?alt=media`;
  let altLogoPath = `${api}/o/${altLogo?.path}?alt=media`;
  let overlayImagePath = `${api}/o/${overlayImage?.path}?alt=media`;
  const eventDate = event.start.toFormat("LLLL d ' at ' h a ZZZZ");

  const handleSuccess = (admin, token) => {
    onSuccess(admin, token);
    setRegistrationComplete(true);
  };

  const setModalVisible = (bool) => {
    setOpen(bool);
    if (bool) {
      analytics.fireEvent({
        category: "Video",
        action: "Hero Video",
        label: window.location.host,
      });
    } else {
      analytics.fireEvent({
        category: "Video",
        action: "Close Button",
        label: window.location.host,
      });
    }
  };

  const AdLinkClick = () => {
    analytics.fireEvent({
      category: "External Link",
      action: "Song Video",
      label: window.location.host,
    });
  };

  return (
    <Layout noPadding noAddons showFooterText showFooterLogo layoutRegistration>
      <Registration>
        <Content as="section" id="registration-content">
          <ContentContainer>
            {themeContext.register.altLogo?.active && (
              <AltLogo src={altLogoPath} />
            )}
            {heading && <Heading>{ReactHtmlParser(heading)}</Heading>}
            {subHeading && <SubHeading>{subHeading}</SubHeading>}
            {body && <Body>{ReactHtmlParser(body)}</Body>}

            {registrationComplete || props.registered ? (
              <RegistrationConfirmation>
                <ConfirmationHeader>Thank you!</ConfirmationHeader>
                <ConfirmationBody>
                  <p>We've successfully received your registration.</p>
                  <p>
                    We'll see you back on this page on
                    <br />
                    <span>{eventDate}</span>
                  </p>
                </ConfirmationBody>
              </RegistrationConfirmation>
            ) : (
              <RegistrationForm formData={event} onSuccess={handleSuccess} />
            )}
          </ContentContainer>

          <ContentAd>
            <ContentAdOverlay>
              <AdLink
                href="https://youtu.be/s3sk71ffTpo"
                target="_blank"
                rel="noopener noreferrer"
                onClick={AdLinkClick}
              >
                <PlayButton src={playIcon} />
                <AdText>
                  <AdTitle>"Set Me Free"</AdTitle>
                  <AdSubtitle>Listen to the full song</AdSubtitle>
                </AdText>
              </AdLink>
            </ContentAdOverlay>
          </ContentAd>
        </Content>

        <ArtistImage
          as="section"
          id="artist-image"
          desktopImage={desktopImage}
          mobileImage={mobileImage}
        >
          {themeContext.register.overlayImage.active && (
            <OverlayContainer>
              <ModalLink onClick={setModalVisible}>
                <OverlayImage src={playIcon} />
                <OverlayTitle>{event.artist}</OverlayTitle>
              </ModalLink>
            </OverlayContainer>
          )}
        </ArtistImage>
      </Registration>

      <VideoModal size="large" open={open} setOpen={setModalVisible}>
        <VideoModalContainer>
          <Video
            src="https://www.youtube.com/embed/S_zenTgWkNw"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </VideoModalContainer>
      </VideoModal>
    </Layout>
  );
});

// ======== REGISTRATION CONTAINER ======== //

const Registration = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 1;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }
`;

// ======== CONTENT / LEFT SIDE ======== //

const Content = styled.div`
  background: #191c22;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 39%;
    max-width: 630px;
  }
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 500px;
  padding: 30px 16px 40px 16px;
  width: 100%;
  height: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    max-width: unset;
    padding: 35px 30px 45px;
  }
`;

const AltLogo = styled.img`
  width: 100%;
  margin-bottom: 24px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    max-width: 54%;
  }
`;

const Heading = styled.h2`
  color: #fdfdfd;
  font-family: ${(props) => props.theme.fontStacks.header};
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 34px;
  }
`;

const SubHeading = styled.h3`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontStacks.header};
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 18px;
  }
`;

const Body = styled.p`
  color: #ffffff;
  font-weight: 300;
  font-family: ${(props) => props.theme.fontStacks.default};
  font-size: 16px;
  margin-bottom: 24px;
  order: 3;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 18px;
  }
`;

const RegistrationConfirmation = styled.div`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-family: ${(props) => props.theme.fontStacks.header};
  text-transform: uppercase;
`;

const ConfirmationHeader = styled.h3`
  font-size: 48px;
  text-transform: uppercase;
  margin-bottom: 20px;
  border-bottom: 5px solid #b00d25;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 72px;
  }
`;

const ConfirmationBody = styled.div`
  p {
    margin-bottom: 20px;
    font-size: 20px;
    font-family: "BrandMedium";
  }

  span {
    font-family: "BrandBold";
    font-size: 32px;
    margin-top: 16px;
    display: inline-block;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    p {
      font-size: 24px;
    }

    span {
      font-size: 36px;
    }
  }
`;

const ContentAd = styled.div`
  display: flex;
  background-image: url(${contentAd});
  background-position: center;
  background-size: cover;
  padding-top: 56.25%;
  position: relative;
`;

const ContentAdOverlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 35%,
    rgba(0, 0, 0, 0) 35%
  );
  padding: 30px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

const AdLink = styled.a`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: #ffffff;
  align-items: center;
  justify-content: center;
`;

const PlayButton = styled.img`
  width: 15%;
  margin-right: 15px;
`;

const AdText = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
`;

const AdTitle = styled.h3`
  font-size: 10vw;
  font-family: ${(props) => props.theme.fontStacks.header};

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 3.75vw;
  }
`;

const AdSubtitle = styled.p`
  font-size: 5vw;
  margin-left: 3.5vw;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 2vw;
    margin-left: 1vw;
  }
`;

// ======== ARTIST IMAGE / RIGHT SIDE ======== //

const ArtistImage = styled.div`
  align-items: flex-start;
  background-color: #000000;
  background-image: url(${(props) => props.mobileImage});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 430px;
  padding-top: ${(9 / 16) * 100}%;
  position: relative;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.desktopImage});
    background-position: 65% 0px;
    flex-grow: 1;
    padding-top: 0;
  }
`;

const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const OverlayImage = styled.img`
  max-width: 150px;
  margin-bottom: 25px;
`;

const OverlayTitle = styled.h3`
  color: #ffffff;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fontStacks.header};
  font-size: 60px;
  text-shadow: 0 3px 12px rgba(0, 0, 0, 0.58);

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 120px;
  }
`;

// ======== MODAL ======== //

const ModalLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    text-decoration: none;
  }
`;

const VideoModal = styled(Modal)`
  justify-content: center;
  min-height: unset;
  position: relative;

  svg {
    top: 10px;
    right: 10px;
  }
`;

const VideoModalContainer = styled.div`
  padding-top: 56.25%;
  position: relative;
`;

const Video = styled.iframe`
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
`;
