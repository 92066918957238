export const register = {
  // Left Side
  content: {
    background: "#191C22",
    align: ["left", "left"],
    maxWidth: ["500px", "640px"],
    padding: ["30px 16px 40px 16px", "35px 35px 45px"],
  },
  // Right Side
  artistImage: {
    background: "#000000",
    width: ["100%", "66%"],
    imageWidth: 762,
    imageHeight: 1098,
  },
  // Left Side Brand Logo
  altLogo: {
    active: false,
    width: ["100%", "54%"],
  },
  overlayImage: {
    active: true,
    width: "175px",
    background: "transparent",
  },
  // Text Elements
  heading: {
    color: "#fdfdfd",
    fontSize: ["22px", "34px"],
    fontWeight: 700,
    maxWidth: ["100%", "495px"],
    marginBottom: ["16px", "16px"],
    textTransform: "uppercase",
    order: 1,
  },
  subHeading: {
    color: "#ffffff",
    fontSize: ["14px", "18px"],
    fontWeight: 700,
    margin: ["0 0 10px 0", "0 0 10px 0"],
    textTransform: "none",
    order: 1,
  },
  body: {
    color: "#ffffff",
    fontSize: ["16px", "18px"],
    lineHeight: ["18px", "20px"],
    fontWeight: 300,
    marginBottom: ["24px", "24px"],
  },
  formHeading: {
    color: "#ffffff",
    fontSize: ["38px", "42px"],
    fontWeight: 700,
    marginBottom: ["16px", "16px"],
    textTransform: "uppercase",
  },
  input: {
    background: "#ffffff",
    backgroundDisabled: "#A6AEB2",
    backgroundError: "#EDB8B4",
    border: "1px solid #E0E0E0",
    borderDisabled: "1px solid #E0E0E0",
    borderError: "2px solid #E45C52",
    borderFocus: "1px solid #E0E0E0",
    borderFocusShadow: "none",
    borderRadius: 0,
    color: "#676767",
    fontSize: ["16px", "16px"],
    lineHeight: ["22px", "22px"],
    fontWeight: 400,
    height: ["40px", "40px"],
    textTransform: "uppercase",
    textAlign: ["center", "center"],
    verticalSpacing: [8, 8],
    horizontalSpacing: [6, 8],
    padding: "0 12px",
    placeholder: {
      color: "#676767",
      fontSize: ["15px", "15px"],
      fontWeight: 400,
      lineHeight: ["22px", "22px"],
      padding: "0 12px",
      textAlign: ["center", "center"],
    },
  },
  checkbox: {
    fill: "#ffffff",
    fillError: "#EDB8B4",
    border: "#E0E0E0",
    checkedFill: "#ffffff",
    checkedBorder: "#E0E0E0",
    borderError: "2px solid #E45C52",
    borderRadius: 0,
    disabledFill: "#dcdcdc",
    disabledBorder: "#dcdcdc",
    disabledCheckedFill: "#dcdcdc",
    disabledCheckedBorder: "#dcdcdc",
    width: "33px",
    height: "33px",
    check: {
      color: "#676767",
      width: "18px",
      height: "18px",
    },
  },
  checkboxLabel: {
    color: "#ffffff",
    fontSize: ["12px", "12px"],
    lineHeight: ["17px", "17px"],
    fontWeight: 300,
    textTransform: "none",
  },
  button: {
    color: "#ffffff",
    fill: "#940003",
    border: "3px solid #ffffff",
    borderRadius: "43px",
    fontSize: ["24px", "24px"],
    lineHeight: ["26px", "26px"],
    fontWeight: 400,
    height: ["60px", "86px"],
    textTransform: "uppercase",
    margin: ["16px 0 0 0", "16px 0 0 0"],
    colorHover: "#0065C3",
    borderHover: "3px solid #ffffff",
    fillHover: "#ffffff",
  },
  formLegal: {
    color: "#fafafa",
    fontSize: ["12px", "12px"],
    lineHeight: ["15px", "15px"],
    fontWeight: 400,
  },
  error: {
    color: "#ffffff",
    fontSize: "12px",
  },
};
