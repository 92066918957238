import React from "react";
import styled from "styled-components";
import { Radio, GridRow, GridColumn, P } from "notes";
import { FieldMessage } from "Components";

export const RadioField = ({ onChange, value, field, error = {} }) => {
  const handleRadio = (value) => {
    onChange({ [field.name]: value });
  };

  return (
    <GridColumn>
      <GridRow>
        {field.options.map(({ id, title, subtitle }) => (
          <InlineRadio
            key={id}
            checked={value === title}
            onChange={() => handleRadio(title)}
            title={!!subtitle && title}
          >
            <StyledLabel>{!!subtitle ? subtitle : title}</StyledLabel>
          </InlineRadio>
        ))}
      </GridRow>
      <FieldMessage active={!!error.status} content={error.message} />
    </GridColumn>
  );
};

const StyledLabel = styled(P)`
  color: ${(props) => props.theme.register.checkboxLabel.color};
`;

const InlineRadio = styled(Radio)`
  margin-top: 4px;
  margin-bottom: 0;
  & + & {
    margin-left: 24px;
  }
  #outer {
    stroke: #fff;
    fill: #fff;
  }
  #inner {
    fill: #222;
  }
`;
