import React, { useState, useReducer, useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { GridColumn, GridRow, H3, P, ButtonPrimary, Label } from "notes";
import {
  FormMessage,
  Field,
  handleErrors,
  firebase,
  EventContext,
} from "Components";
import { reducer, responsiveCalc } from "Modules";
import analytics from "services/analytics";

export const RegistrationForm = ({ formData, onSuccess, ...props }) => {
  const [registration, setRegistration] = useReducer(reducer, {});
  const [errors, setErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { event } = useContext(EventContext);
  const eventDate = event.start.toFormat("LLLL d ' at ' h a ZZZZ");

  const {
    artist,
    title,
    buttonLabel,
    fields,
    formHeading,
    formId,
    formLegal,
    ageGateMessage,
    mastercampaignId,
  } = formData;

  const handleChange = (value, name) => {
    setRegistration(value);
    delete errors[name];
    setErrors(errors);
  };

  const handleSubmit = async () => {
    let errorObject = handleErrors(fields, registration);
    if (!errorObject) {
      setSubmitting(true);
      try {
        const submitForm = firebase.functions().httpsCallable("leads-submit");
        await submitForm({
          form: {
            ...registration,
          },
          email: registration.EMAIL,
          formId: formId,
          mcId: mastercampaignId,
          eventId: event.id,
        }).then(({ data }) => {
          if (!!data.token) {
            onSuccess(false, data.token);
            setSubmitting(false);
            analytics.fireEvent({
              category: "Form Submit Part 1",
              action: "Success",
              label: window.location.host,
            });
          }
        });
      } catch (error) {
        setSubmitting(false);
      }
    } else {
      setErrors(errorObject);
    }
  };

  return (
    <>
      <FormHeader>
        {formHeading && <FormTitle>{ReactHtmlParser(formHeading)}</FormTitle>}
        <EventDate>{eventDate}</EventDate>
      </FormHeader>
      <Form>
        {fields &&
          fields
            .sort((a, b) => a.order - b.order)
            .map((field, index) => {
              return (
                <FieldContainer fullSize={field.span === "full"} key={index}>
                  {field.type !== "optIn" && !!field.label && (
                    <Label>{field.label}</Label>
                  )}
                  <Field
                    field={field}
                    fieldCheck={
                      !!field.requires ? registration[field.requires] : true
                    }
                    value={registration[field.name]}
                    onChange={(value) => handleChange(value, field.name)}
                    error={{
                      status: errors[field.name],
                      message: "This is a required field and cannot be blank.",
                    }}
                  />
                </FieldContainer>
              );
            })}
      </Form>
      <FormMessage
        active={!!errors?.AGE_GATE}
        content={ageGateMessage}
        style={{ order: 6 }}
      />
      <ButtonContainer>
        {submitting ? (
          <RegisterButton disabled>Loading</RegisterButton>
        ) : (
          <RegisterButton onClick={() => handleSubmit()}>
            {buttonLabel ?? "Register"}
          </RegisterButton>
        )}
      </ButtonContainer>
      {formLegal && <FormLegal>{ReactHtmlParser(formLegal)}</FormLegal>}
    </>
  );
};

const FormHeader = styled.div`
  border-left: 5px solid #b00d25;
  padding-left: 15px;
  margin-bottom: 15px;
`;

const FormTitle = styled.h3`
  color: #ffffff;
  font-family: "BrandMedium";
  font-size: 28px;
  text-transform: uppercase;

  span {
    font-size: 68px;
    font-family: "BrandBold";
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 32px;
  }
`;

const EventDate = styled.p`
  font-size: 28px;
  color: #ffffff;
  font-family: "BrandBold";
  text-transform: uppercase;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 32px;
  }
`;

const Form = styled(GridRow)`
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -${(props) => props.theme.register.input.horizontalSpacing[0] / 2}px;
  margin-right: -${(props) => props.theme.register.input.horizontalSpacing[0] / 2}px;
  order: 5;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin-left: -${(props) => props.theme.register.input.horizontalSpacing[1] / 2}px;
    margin-right: -${(props) => props.theme.register.input.horizontalSpacing[1] / 2}px;
  }
`;

const FieldContainer = styled(GridColumn)`
  flex: 0 1 ${(props) => (props.fullSize ? "100%" : "50%")};
  padding: ${(props) => props.theme.register.input.verticalSpacing[0]}px
    ${(props) => props.theme.register.input.horizontalSpacing[0] / 2}px 0
    ${(props) => props.theme.register.input.horizontalSpacing[0] / 2}px;
  font-weight: ${(props) => props.theme.register.input.fontWeight};
  text-transform: ${(props) => props.theme.register.input.textTransform};

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: ${(props) => props.theme.register.input.verticalSpacing[1]}px
      ${(props) => props.theme.register.input.horizontalSpacing[1] / 2}px 0
      ${(props) => props.theme.register.input.horizontalSpacing[1] / 2}px;
  }
`;

const ButtonContainer = styled(GridColumn)`
  margin: ${(props) => props.theme.register.button.margin[0]};
  order: 7;
  width: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin: ${(props) => props.theme.register.button.margin[1]};
  }
`;

const RegisterButton = styled.button`
  align-items: center;
  background-color: transparent;
  color: #ffffff;
  font-family: "BrandBold";
  font-size: 40px;
  transition: all ease 0.3s;
  text-transform: uppercase;
  height: 125px;
  width: 100%;
  outline: none;
  border: 2px solid #ffffff;

  &:hover,
  &:focus {
    background-color: #ffffff;
    color: #191c22;
  }
`;

const FormLegal = styled.p`
  color: ${(props) => props.theme.register.formLegal.color};
  font-family: ${(props) => props.theme.fontStacks.default};
  font-size: ${(props) => props.theme.register.formLegal.fontSize[0]};
  font-weight: ${(props) => props.theme.register.formLegal.fontWeight};
  padding: 8px 3px 0 3px;
  order: 8;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: ${(props) => props.theme.register.formLegal.fontSize[1]};
  }

  a,
  a:hover {
    color: ${(props) => props.theme.register.formLegal.color};
    text-decoration: underline;
  }
`;
