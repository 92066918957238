import { useContext, useEffect } from "react";
import { TimeContext } from "Components";
import analytics from "services/analytics";

export const Heartbeater = () => {
  const { time } = useContext(TimeContext);
  useEffect(() => {
    if (time.second === 0) {
      analytics.fireEvent({
        category: "heartbeats",
        action: "heartbeat_1min",
        label: window.location.host,
      });
    }
  }, [time]);

  return null;
};
