import React, { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import { Layout, BodySection } from "../Layout";
import styled, { ThemeContext } from "styled-components";
import { GridColumn, H1, H4 } from "notes";

export const PostShow = ({ event = {} }) => {
  const {
    heading,
    subHeading,
    body,
    thankYouHeader,
    thankYouBody,
    overlayImage,
  } = event;
  const themeContext = useContext(ThemeContext);
  return (
    <Layout
      heroContent={
        <HeroSection>
          <ThankYouHeader>{thankYouHeader}</ThankYouHeader>
          <ThankYouBody>{thankYouBody}</ThankYouBody>
          {overlayImage?.path && (
            <OverlayImage
              src={`${process.env.REACT_APP_FIRE_BASE_STORAGE_API}/o/${overlayImage?.path}?alt=media`}
            />
          )}
        </HeroSection>
      }
      heading={heading}
      subHeading={subHeading}
      heroPadding={themeContext.postShow?.heroPadding}
      showFooterLogo
    >
      {body ? <BodySection>{ReactHtmlParser(body)}</BodySection> : undefined}
    </Layout>
  );
};

const HeroSection = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.postShow.video.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  padding: 0 30px;
`;

const ThankYouHeader = styled.h1`
  font-size: 72px;
  color: white;
  font-family: ${(props) => props.theme.fontStacks.header};
  text-transform: uppercase;
  letter-spacing: 5px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 150px;
  }
`;

const ThankYouBody = styled.h4`
  font-size: 24px;
  color: white;
  font-weight: 100;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 40px;
  }
`;

const OverlayImage = styled.img`
  margin-top: ${(props) => props.theme.postShow.overlayImage.marginTop[1]};
  width: ${(props) => props.theme.postShow.overlayImage.width[1]};

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    margin-top: ${(props) => props.theme.postShow.overlayImage.marginTop[0]};
    width: ${(props) => props.theme.postShow.overlayImage.width[0]};
  }
`;
