import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Duration } from "luxon";
import { TimeContext, Countdown } from "Components";
import { Layout, BodySection } from "../Layout";

export const PreShow = ({ event = {} }) => {
  const { time } = useContext(TimeContext);
  const themeContext = useContext(ThemeContext);
  const { heading, subHeading, body, countdownLabel } = event;

  const timeToStart =
    time < event.start &&
    Duration.fromMillis(event.start - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  const timer = {
    label: countdownLabel ?? "Show Starts In",
    days: timeToStart.days,
    hours: timeToStart.hours,
    minutes: timeToStart.minutes,
    seconds: timeToStart.seconds,
  };

  return (
    <Layout
      heading={heading}
      subHeading={subHeading}
      heroContent={<Countdown {...timer} />}
      heroPadding={themeContext.preShow?.heroPadding}
      showFooterLogo
    >
      {body ? <BodySection>{ReactHtmlParser(body)}</BodySection> : undefined}
    </Layout>
  );
};
